// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

[data-math] {
    font-family: "Lato", sans-serif !important;
    font-size: 15px;
  }
  